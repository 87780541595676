import React, {createContext, useState, useEffect, useCallback} from 'react';
import {Routes, Route} from 'react-router-dom';
import ROUTES from './constants/routes';
import './App.scss';
import Spinner from './components/common/spinner';
import {handleLazyLoadError} from './helpers';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import {localStorageGetItem} from 'helpers/storage';
import {STORAGE_KEY} from 'constants/index';
import {postVerifyByTokenApi} from 'apis/index';
import {toast} from 'react-toastify';
import {handleErrorAuthResponse} from 'helpers/index';
import {localStorageRemoveItem} from 'helpers/storage';

const HomePage = React.lazy(() =>
  import('./pages/Home').catch((error) => {
    handleLazyLoadError(error);
  })
);

const PageNotFound = React.lazy(() =>
  import('./pages/PageNotFound').catch((error) => {
    handleLazyLoadError(error);
  })
);

const LoginPage = React.lazy(() =>
  import('./pages/Login').catch((error) => {
    handleLazyLoadError(error);
  })
);

const Loading = React.lazy(() =>
  import('./components/common/loading').catch((error) => handleLazyLoadError(error))
);

export const LoginContext = createContext();

function App() {
  const [isLogin, setIsLogin] = useState(false);
  // const [infoCS, setInfoCS] = useState(null);
  const [idRequest, setIdRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const postVerifyByToken = useCallback(async (token) => {
    let params = {
      user_sign: token,
    };

    setIsLoading(true);
    try {
      const response = await postVerifyByTokenApi(params);
      // console.log('response postVerifyByPassword', response);

      if (response?.isSuccess && response?.data?.token) {
        setIsLogin(true);
        setIsLoading(false);
      } else {
        setIsLogin(false);
        setIsLoading(false);
        localStorageRemoveItem(STORAGE_KEY.ACCESS_TOKEN);

        if (response?.messages?.length > 0) {
          response?.messages?.forEach((err) =>
            toast.error(err?.content, {
              autoClose: 2000,
              position: 'top-center',
            })
          );
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsLogin(false);
      localStorageRemoveItem(STORAGE_KEY.ACCESS_TOKEN);

      if (error) {
        handleErrorAuthResponse(error);
      } else {
        console.error('error from postVerifyByTokenApi', error);
        toast.error('Phiên đăng nhập hết hạn, vui lòng đăng nhập lại.', {
          autoClose: 2000,
          position: 'top-center',
        });
      }
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      let token = localStorageGetItem(STORAGE_KEY.ACCESS_TOKEN);

      if (token && !isLogin) {
        await postVerifyByToken(token);
      } else {
        if (isLogin) setIsLogin(false);
      }
    };

    getData();
  }, []);

  return (
    <div className="App">
      <LoginContext.Provider
        value={{
          isLogin: isLogin,
          setIsLogin: setIsLogin,
          // infoCS: infoCS,
          // setInfoCS: setInfoCS,
          idRequest: idRequest,
          setIdRequest: setIdRequest,
          isLoading: isLoading,
          setIsLoading: setIsLoading,
        }}
      >
        <Loading isLoading={isLoading}>
          <React.Suspense fallback={<Spinner />}>
            <Routes>
              <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
              <Route path={ROUTES.HOME} element={<HomePage />} />
              <Route path={ROUTES.LOGIN} element={<LoginPage />} />
            </Routes>
          </React.Suspense>
          <ToastContainer />
        </Loading>
      </LoginContext.Provider>
    </div>
  );
}

export default App;
