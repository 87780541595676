import axios from 'axios';
import {toast} from 'react-toastify';
import {localStorageGetItem, localStorageRemoveItem} from './storage';
import {STORAGE_KEY, CODE_KEY, END_POINT_THIRD_PARTY} from 'constants/index';
import ROUTES from 'constants/routes';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Cross-Origin-Opener-Policy'] = 'same-origin-allow-popups';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  (config) => {
    // const token = localStorageGetItem(STORAGE_KEY.ACCESS_TOKEN);
    // if (token) {
    //   config.headers['Authorization'] = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res) => {
    const {data} = res;
    return data;
  },
  (error) => {
    console.error('🚀 ~ file: apiUtils.js:207 ~ error:', error);

    if (error?.response?.status === CODE_KEY.UNAUTHORIZED_STATUS) {
      const token = localStorageGetItem(STORAGE_KEY.ACCESS_TOKEN);

      if (!token) {
        return Promise.reject(error);
      } else {
        localStorageRemoveItem(STORAGE_KEY.ACCESS_TOKEN);

        toast.error('Phiên đăng nhập hết hạn, vui lòng đăng nhập lại.', {
          autoClose: 1500,
          position: 'top-center',
        });

        setTimeout(() => {
          window.location.pathname = ROUTES.LOGIN;
        }, 1500);

        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export function requestApi({endpoint, method, body, responseType = 'json', ...config}) {
  const baseUrl = `${process.env.REACT_APP_END_POINT}`;

  const reqObj = {
    method: method,
    url: `${baseUrl}${endpoint}`,
    data: body,
    responseType: responseType,
    ...config,
  };

  reqObj.headers = {Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN_CSR}`};

  return axios.request(reqObj);
}

export function requestAuthApi({
  serviceName = END_POINT_THIRD_PARTY,
  endpoint,
  method,
  body,
  responseType = 'json',
  ...config
}) {
  const baseUrl = `${process.env.REACT_APP_API_URL}${serviceName}`;

  const reqObj = {
    method: method,
    url: `${baseUrl}${endpoint}`,
    data: body,
    responseType: responseType,
    ...config,
  };
  const token = localStorageGetItem(STORAGE_KEY.ACCESS_TOKEN);

  if (token) {
    reqObj.headers = {Authorization: `Bearer ${token}`};
  }

  return axios.request(reqObj);
}
