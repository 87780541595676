export const AUTH = {
  postVerifyByPasswordUrl: '/api/v1/vitadairy/account/verify-by-pwd',
  postVerifyByTokenUrl: '/api/v1/vitadairy/account/verify-by-token',
};

export const LOYALTY = {
  getUserRequestByIdUrl: (id) =>
    `/api-v3/loyalty/v1/api-v3/loyalty/hotline-history-point/user-request-history-point/${id}`,
  postAddPointUrl: '/api-v3/loyalty/v1/api-v3/loyalty/hotline-history-point/add-point',
};
