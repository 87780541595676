import {requestApi, requestAuthApi} from 'helpers/apiUtils';
import {AUTH, LOYALTY} from './api-definitions';
import {API_METHOD} from 'constants/index';

// AUTH
export const postVerifyByPasswordApi = (params) => {
  return requestAuthApi({
    endpoint: AUTH.postVerifyByPasswordUrl,
    method: API_METHOD.POST,
    body: params,
  });
};

export const postVerifyByTokenApi = (params) => {
  return requestAuthApi({
    endpoint: AUTH.postVerifyByTokenUrl,
    method: API_METHOD.POST,
    body: params,
  });
};

// LOYALTY
export const getUserRequestByIdApi = (id) => {
  return requestApi({
    endpoint: LOYALTY.getUserRequestByIdUrl(id),
    method: API_METHOD.GET,
  });
};

export const postAddPointApi = (params) => {
  return requestApi({
    endpoint: LOYALTY.postAddPointUrl,
    method: API_METHOD.POST,
    body: params,
  });
};
