import {IMAGE_LINKS} from 'constants/info';

const HomeForMobile = () => {
  return (
    <>
      <div>
        <div>
          <img src={IMAGE_LINKS.LOGO_BACKGROUND} alt="Logo VitaDairy" />
        </div>
        <div className=" px-10 py-10 font-semibold text-center bg-background">
          <div className="mb-4 text-black text-[24px]">THÔNG BÁO</div>
          <div className="text-primary text-[18px]">
            Để thực hiện tích xu hộ cho khách hàng, bạn vui lòng truy cập trên thiết bị máy tính.
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeForMobile;
