export const BUTTON_TEXT = {
  LOGIN: 'ĐĂNG NHẬP',
  CONFIRM: 'XÁC NHẬN',
  TRY_AGAIN: 'THỬ LẠi',
  LOGOUT: 'ĐĂNG XUẤT',
  EARNING_COIN: 'TÍCH XU HỘ',
};

export const ID_FIELD = 'id';

export const TYPE_RESPONSE = {
  SUCCESS: 'SUCCESS',
  ERROR_WRONG_QR_CHARACTER: 'SB_QR_ERROR',
  ERROR_QR_USED: 'SB_QR_USED',
  ERROR_QR_NO_EXIST: 'SB_QR_NOEXIST',
  ERROR_QR_INCORRECT: 'SB_QR_UNMATCH',
  ERROR_QR_OUTDATE: 'SB_QR_OUTDATE',
  ERROR_SPOON_USED: 'SB_SPOON_USED',
  ERROR_SPOON_NO_EXIST: 'SB_SPOON_NOEXIST',
  ERROR_SPOON_FORERR: 'SB_SPOON_FORERR',
  ERROR_SPOON_NOT_MATCH_BRAND: 'SB_SPOON_UNMATCH_BRAND',
  ERROR_SPOON_NOT_MATCH_WEIGHT: 'SB_SPOON_UNMATCH_WEIGHT',
  ERROR_SPOON_NOT_MATCH_QR: 'SB_DATE_1722',
  ERROR_SPOON_NOT_MATCH_QR_DATE_AFTER: 'SB_DATE_AFTER722',
  // ERROR_OUT_OF_TIMES_SPOON_EXCHANGES: 'ERROR_OUT_OF_TIMES_SPOON_EXCHANGES',
  // ERROR_OUT_OF_TIMES_EARNING_COIN: 'ERROR_OUT_OF_TIMES_EARNING_COIN',
  ERROR_EXPIRE: 'SB_EXPIRE',
  ERROR_OVER_ADD_POINT: 'SB_OVERADDPOINT',
  // ERROR_DEFAULT: 'ERROR_DEFAULT',
  ERROR_SYSTEM_OVERLOAD: 'SB_QR_SYSTEM_ERROR',
  ERROR_SYSTEM_MAINTAINING: 'SB_DEFAULT_ERROR',
  ERROR_HOTLINE_USED: 'SB_HOTLINE_SUCCESS',
  ERROR_BLOCKED_ACCOUNT: 'error.blocked_account',
  ERROR_BLOCKED_ACCOUNT_SAMEQR: 'SB_BLOCK_SAMEQR',
  ERROR_SPOON_CODE_ALREADY_USED_BY_HOTLINE: 'SPOON_CODE_ALREADY_USED_BY_HOTLINE',
};

export const DESCRIPTION_RESPONSE_TEXT = {
  SUCCESS: <>Bạn đã tích xu thành công cho khách hàng</>,
  ERROR_WRONG_QR_CHARACTER: <span className="font-semibold">Nhập sai ký tự QR</span>,
  ERROR_QR_USED: <span className="font-semibold">QR đã được sử dụng</span>,
  ERROR_QR_NO_EXIST: <span className="font-semibold">Mã QR không tồn tại</span>,
  ERROR_QR_INCORRECT: <span className="font-semibold">Mã QR bị lỗi</span>,
  ERROR_QR_OUTDATE: <span className="font-semibold">Mã QR hết hạn sử dụng</span>,
  ERROR_SPOON_USED: (
    <>
      Mã muỗng <span className="font-semibold">đã được sử dụng</span>
    </>
  ),
  ERROR_SPOON_NO_EXIST: (
    <>
      Mã muỗng <span className="font-semibold">không hợp lệ</span>
    </>
  ),
  ERROR_SPOON_NOT_MATCH_BRAND: (
    <>
      Mã muỗng{' '}
      <span className="font-semibold">
        không đúng <br />
        sản phẩm
      </span>
    </>
  ),
  ERROR_SPOON_NOT_MATCH_WEIGHT: (
    <>
      Mã muỗng <span className="font-semibold">không đúng trọng lượng sản phẩm</span>
    </>
  ),
  ERROR_SPOON_NOT_MATCH_QR: (
    <>
      Mã muỗng{' '}
      <span className="font-semibold">
        không khớp <br />
        với mã QR
      </span>
    </>
  ),
  ERROR_SPOON_NOT_MATCH_QR_DATE_AFTER: (
    <>
      Mã muỗng{' '}
      <span className="font-semibold">
        không khớp <br />
        với mã QR
      </span>
    </>
  ),
  // ERROR_OUT_OF_TIMES_SPOON_EXCHANGES: (
  //   <>
  //     Khách hàng <span className="font-semibold">đã đổi thành công đủ 3 muỗng trong hôm nay</span>
  //   </>
  // ),
  // ERROR_OUT_OF_TIMES_EARNING_COIN: (
  //   <>
  //     Bạn đã thực hiện <span className="font-semibold">quá số lần tích xu hộ</span>
  //   </>
  // ),
  ERROR_EXPIRE: (
    <>
      Sản phẩm <span className="font-semibold">hết hạn tích xu</span>
    </>
  ),
  ERROR_OVER_ADD_POINT: (
    <>
      Khách hàng <span className="font-semibold">đã sử dụng hết 3 lượt tích xu</span> trong ngày hôm
      nay
    </>
  ),
  // ERROR_DEFAULT: '',
  ERROR_SYSTEM_OVERLOAD: (
    <>
      Hệ thống đang quá tải.
      <br />
      Vui lòng thử lại sau <span className="font-semibold">30 phút</span>
    </>
  ),
  ERROR_SYSTEM_MAINTAINING: <>Hệ thống đang bảo trì</>,
  ERROR_HOTLINE_USED: <>QR/MM đã được được tích thành công từ Hotline</>,
  ERROR_BLOCKED_ACCOUNT: (
    <>
      Tài khoản khách hàng <br />
      đã bị khóa!
    </>
  ),
  ERROR_BLOCKED_ACCOUNT_SAMEQR: (
    <>
      Tài khoản khách hàng <br />
      đã bị khóa!
    </>
  ),
  ERROR_SPOON_CODE_ALREADY_USED_BY_HOTLINE: <>QR/MM đã được được tích thành công từ Hotline</>,
};

export const STORAGE_KEY = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
};

export const CODE_KEY = {
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  ERROR_NETWORK: 'ERROR_NETWORK',
  TIME_OUT: 408,
  UNAUTHORIZED_STATUS: 401,
  NOT_INTERNET: 'NOT_INTERNET',
  UNDEFINED: 'UNDEFINED',
  UNKNOWN: 'UNKNOWN',
};

export const API_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const API_SUCCESS_STATUS = 1000;

export const END_POINT_THIRD_PARTY = '/third-party';

export const CLAIM_TYPES = {
  NAME: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  ROLE: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
};
